import React, {useState, useEffect} from "react";
import { Container, Row } from "react-bootstrap";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/bg_faqs.jpg";
import RecursiveAccordion from "../components/recursive_accordion/recursive_accordion";
import "./preguntas-frecuentes.css";
import { apiRequest } from "../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const pageTitle = 'Preguntas Frecuentes | Balanz';
const pageDesc = 'Encontrá todas las respuestas a tus consultas acerca de cómo invertir en Balanz y las operatorias del Mercado de Capitales de Argentina.';

const FaqDDEScript = (faq_data) => ({ __html: JSON.stringify(faq_data) })

const FaqPage = () => {
    const [faq, setFaq] = useState([
        { id: 1, title: "Apertura de Cuenta y Costos", body: "", parentId: 0 },
        { id: 2, title: "Operatoria y Transferencias", body: "", parentId: 0 },
        { id: 3, title: "Asesoramiento", body: "", parentId: 0 },
        {id:22, title:"Cuenta Especial de Regularización de Activos (CERA)", body:"", parentId:0},
    ])
    const FaqDDE = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
    };

    const FaqData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: [1, 2, 3]}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    FaqData.allFaqs.nodes.forEach(f => {
        FaqDDE.mainEntity.push({
            "@type": "Question",
            "name": f.question,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": f.answer
            }
        })
    })

    useEffect(() => {
        apiRequest
            .get("/v1/faq/1,2,3,22")
            .then((res) => {
                const data = [...faq]
                let questions = []
                res.data.data.forEach(d => {
                    data.push({
                        id: d.id,
                        title: d.question,
                        body: d.answer,
                        parentId: d.category_id
                    })
                })
                setFaq(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])
    
    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerFaq"
                bannerTrasparecy="bannerMask"
                productTitle="Preguntas frecuentes"
                bannerImage={Banner}
                bannerImageClassName="faqsBannerImage"
                textContainerClassName="textContainer"
                bannerText={false}
                bannerTextClassName="bannerTextInversiones"
                bannerImageMobile={Banner}
                mobile={false}
            />
            <section className="fullwidth mainfaq">
                <Container>
                    <Row className="justify-content-center py-3 py-sm-2 py-md-3 py-md-4 py-lg-5">
                        <RecursiveAccordion items={faq} recurse={false} defaultOpenAll={true} />
                        <script type="application/ld+json" dangerouslySetInnerHTML={ FaqDDEScript(FaqDDE) }></script>
                    </Row>
                </Container>
            </section>
        </>
    );

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"preguntas-frecuentes"}></Layout>
        </main>
    );
};

export default FaqPage;