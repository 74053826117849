import React from "react";
import { Accordion } from "react-bootstrap";


const Recursive_accordion = ({ items, recurse = false, defaultOpenAll = false}) => {
    let keyIndex = '';
    if(defaultOpenAll){
        keyIndex = 'defaultOpenAll'
    }
    return (
        <>
        <Accordion defaultActiveKey={keyIndex} className={`main-accordion-container ${keyIndex}`} >
            {items.map((item, index) => {
                let children = items.filter((i) => {
                    return i.parentId === item.id;
                });

                let hasChildren = children.length > 0;
                // console.log('index', index)
                if (item.parentId === 0) {
                    if(defaultOpenAll){
                        keyIndex = 'defaultOpenAll'
                    }else{
                        keyIndex = index
                    }
                    return (
                        <Accordion.Item eventKey={keyIndex} key={index} data-key={index} className="main-item" >
                            <Accordion.Header className="faq-question main-header">{item.title}</Accordion.Header>
                            {hasChildren && (
                                <Accordion.Body className="main-body">
                                    <Recursive_accordion items={children} recurse={true} />
                                    {/*<div dangerouslySetInnerHTML={{ __html: item.body }} />*/}
                                </Accordion.Body>
                            )}
                            {!hasChildren && (
                                <Accordion.Body><div dangerouslySetInnerHTML={{ __html: item.body }} /></Accordion.Body>
                            )}
                        </Accordion.Item>
                    );
                }

                if (recurse) {
                    return (
                        <Accordion.Item eventKey={index} key={index} >
                            <Accordion.Header className="faq-question">{item.title}</Accordion.Header>
                            {hasChildren && (
                                <Accordion.Body className="faq-answer">
                                    <Recursive_accordion items={children} recurse={true} />
                                   {/* <div dangerouslySetInnerHTML={{ __html: item.body }} />*/}
                                </Accordion.Body>
                            )}
                            {!hasChildren && (
                                <Accordion.Body className="faq-answer"><div dangerouslySetInnerHTML={{ __html: item.body }} /></Accordion.Body>
                            )}
                        </Accordion.Item>
                    );
                }
            })}
        </Accordion>

        </>
    );
};
export default Recursive_accordion;
// return <Recursive_accordion items={baseItems} recurse={false} />;
